<template>
  <div id="post-detail" v-loading="loading">
    <div class="app-top">
      <!-- <swiper-list :dataList="swiperList" :height="500" :interval="4000"></swiper-list> -->
    </div>
    <div class="container">
      <div class="f-box">
        <div class="box" id="detail">
          <h3 class="title">{{detail.title||'无标题'}}</h3>
          <preview style="margin-top: 20px;" :content="detail.content"></preview>
          <div style="text-align: center;color:#ddd;font-size: 12px;">{{ invite||'-' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/mtlk'
import preview from './components/preview.vue'

export default {
  name: 'MtlkDetail',
  components: { preview },
  data() {
    return {
      loading: true,
      code: '',
      invite: '',
      detail: {}
    }
  },
  watch: {
    // '$route.params.code'() {
    //   this.code = this.$route.params.code
    //   this.getOne()
    // }
  },
  computed: {
    // code() {
    //   return this.$route.params.code
    // }
  },
  created() {
    console.log(this.$route.params)
    this.code = this.$route.params.code
    this.invite = this.$route.query.invite || this.$route.query.i || null
    if (this.code) {
      this.getOne()
    }
  },
  methods: {
    getOne() {
      this.loading = true
      API.getOne({ id: this.code, invite: this.invite }).then(res => {
        this.detail = res.data
        document.title = `${this.detail.title}`
        this.loading = false
      })
    }
  },

  mounted() {}
}
</script>

<style>
/* 移动端 */
@media screen and (max-width: 576px) {
  #post-detail .f-box .title {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
}
</style>
<style lang="scss" scoped>
// 移动端 vhtml里元素 不生效
// @media screen and (max-width: 576px) {
//   #post-detail {
//     .f-box {
//       h3 {
//         font-size: 18px !important;
//         font-weight: 500 !important;
//       }
//       img {
//         width: 100% !important;
//         padding: 10px !important;
//       }
//     }
//   }
// }
// 电脑端
.app-top {
  margin-top: 58px;
}
#post-detail {
  .container {
    margin-top: 20px;
    padding-bottom: 30px;
    background-color: #fff;

    .f-box {
      margin: 10px auto 0 auto;
      .title {
        font-size: 18px !important;
        font-weight: 600 !important;
        padding: 15px 0;
        text-align: center;
        border-bottom: 1px solid #e6e6e6;
      }
      .box {
        padding: 10px 0;
      }
    }
  }
}
</style>