<template>
  <div id="mtlk-html">
    <div v-if="content" v-html="content"></div>
    <div v-else>
      <el-empty description="暂无预览"></el-empty>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  computed: {},
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style>
@media screen and (max-width: 576px) {
  #mtlk-html img {
    max-width: 100% !important;
  }
}
#mtlk-html {
  line-height: 1.5rem;
  color: #666;
  font-size: 14px;
}
#mtlk-html .ql-align-right {
  text-align: right !important;
}
#mtlk-html .ql-align-center {
  text-align: center !important;
}
#mtlk-html p {
  margin: 5px 0;
}
</style>